import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"


//until I can a way to pass these slugs around
import {kebabCase} from "lodash"



const ProjectSectorRepeater = ({ props }) => {
  return (
    <StyledPortfolioSections>
      {props.map(item => (
          <StyledLink to={"/projects/" + kebabCase(item.title)}>
            <h2>{item.title}</h2>
            <Img fluid={item.cardImage400.childImageSharp.fluid} />
         </StyledLink>


      ))}
    </StyledPortfolioSections>
  )
}

const StyledPortfolioSections = styled.div`
  margin-top:40px;
  display:flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap:wrap;
`

const StyledLink = styled(Link)`
  margin:0 10px;
  width: calc(25% - 20px);
  text-decoration:none;
  display:flex;
  flex-direction:column;

  @media (max-width:700px){
    width: calc(50% - 20px);
  }

  h2{
    flex-grow:1;
    display:flex;
    justify-content:start;
    align-items:center;
    font-size:1.3rem;
  }

  

`;


export default ProjectSectorRepeater
