import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ProjectSectorRepeater from "../components/projectSectorRepeater"
import SEO from "../components/seo"

export default ({ data }) => {
  const pageData = data.pageData
  const services = pageData.frontmatter.services.filter(a => a.hidden === false);

  return (
    <Layout>
      <SEO
        title={pageData.frontmatter.title}
        description={pageData.frontmatter.metaDescription}
      />
      <div>
        <ProjectSectorRepeater props={services} />
      </div>
    </Layout>
  )
}

export const PROJECTS_PAGE_QUERY = graphql`
  query getProjectsPage {
    pageData: markdownRemark(
      frontmatter: { templateKey: { eq: "projects-page" } }
    ) {
      frontmatter {
        title
        metaDescription
        services {
          text
          title
          hidden
          cardImage400: image {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    
  }
`


